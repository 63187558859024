.expanded {
}

.collapsed {
}

.cardContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 20px;
  background: rgba(0, 0, 0, 0.3);
  background: linear-gradient(0deg, #2b0129bf, 30%, #000000a1);
  background: linear-gradient(0deg, #000000a1, 30%, #2b0129bf);
  border-radius: 15px;
  margin-bottom: 30px;
}

/* Maybe add this back... */
/* .cardContainer,
.cardContainer * {
  transition: all 0.2s ease-in-out;
} */

.expanded .cardContainer {
  flex-direction: column;
}

.projectTitle {
  margin: 0px 0px 15px 0px;
}

.cardContent {
  display: flex;
  flex-wrap: wrap;
}

.iconContainer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkList {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}

.linkList li {
  padding: 0;
  margin: 0;
}

.media {
  background: gray;
  border-radius: 15px;
}

.collapsed .media {
  width: 160px;
  height: 160px;
  margin-right: 20px;
  object-fit: cover;
  margin-top: auto;
  margin-bottom: auto;
}

.expanded .media {
  width: 100%;
  height: auto;
  min-height: 200px;
  margin-bottom: 15px;
}

.expanded .projectTitle {
  margin-top: 15px;
}
.cardContainer .mediaWrapper {
  min-width: 200px;
  min-height: 200px;
  width: 200px;
  height: 200px;
  display: flex;
}

.upperContent {
  flex: 1;
}

.expanded .upperContent {
  min-width: 100%;
}

.bottomIcon {
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.expanded .bottomIcon {
  transform: rotate(180deg);
}

.collapsed .cardContainer .mediaWrapper {
  min-width: auto;
  min-height: auto;
  width: 100%;
  height: auto;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.projectDescription {
  font-size: 40px;
  font-family: 'Fira-Sans';
  font-weight: 400;
}

.icon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  margin-top: 4px;
}

.linkItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
  line-height: 1;
}

.linkItem a,
.linkItem .icon {
  color: #fd6389;
}

.pillContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.pillContainer .pill {
  margin-right: 8px;
  background: #3d0079;
  border-radius: 30px;
  color: white;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .cardContent {
    flex-direction: column;
  }
  .cardContainer {
    padding: 20px;
  }
  .collapsed .media {
    width: 100%;
    height: 30vw;
    margin: 0;
    margin-bottom: 20px;
  }
}
