.aboutmeMainContainer {
  z-index: 1;
  position: relative;
}

.aboutMeHeader {
  margin-top: 0px;
}
.contentWrapper {
  flex: 1;
  position: relative;
  padding: 60px;
  max-width: 100%;
}
.aboutMeWrapper {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: lightgray;
  text-align: left;
}

.aboutMeItem {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.scrollItemsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}
.topDecorStyle {
  color: white;
  display: flex;
  width: 70%;
  position: absolute;
  left: -45px;
  top: -75px;
  z-index: 0;
  display: none;
}

.innerWrapper {
  position: relative;
  max-width: 600px;
  margin: auto;
}

.bottomDecorStyle {
  color: white;
  display: flex;
  width: 70%;
  position: absolute;
  right: -35px;
  bottom: -20px;
  transform: rotate(180deg);
  z-index: -1;
  display: none;
}

.button {
  cursor: pointer;
  background: #4b087bb6;
  background: #d4205f;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 14px 29px;
  border: none;
  border-radius: 8px;
  line-height: 1;
  box-shadow: 0px 4px 28px rgba(253, 99, 137, 0.6);
  margin: 50px 0 80px;
}

.contentContainersWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.brackets {
  top: -22px;
  left: -22px;
  font-size: 33px;
  font-weight: 200;
  position: absolute;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollItemsWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollItemsWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* Add this to enable scrolling when necessary */
}

.animateMe {
  transition: all 0.3s ease-in-out;
  transform-origin: right;
  /* transform: rotate(10deg) translateY(-90px); */
  /* transform: translateY(-90px); */
  transform: translateX(90px);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms;
  /* padding-bottom: 100px; */
}

.fadeIn {
  /* transform: rotate(0deg) translateY(0px); */
  /* transform: translateY(0px); */
  transform: translateX(0px);
  opacity: 1;
  transition-delay: 300ms;
  visibility: visible;
  /* padding-bottom: 0px; */
}
/* 600, 768, 992, 1200 */

@media only screen and (max-width: 1024px) {
  .aboutmeMainContainer {
    /* width: 70%; */
    /* width:85vw; */
  }
  .aboutmeItemHolder {
    /* margin-right: 35px; */
  }

  .contentContainersWrapper {
    display: flex;
    justify-content: center;
    margin-right: 20%;
    width: 80vw;
    margin-left: -150px;
  }
  .aboutmeDiscription {
    font-size: 14px;
    font-family: Rajdhani;
    letter-spacing: 0.1rem;
  }
  .topDecorStyle {
    /* color: white;
    display: flex;
   width: 70%; 
    position: absolute;
    left: -100px;
    top: -10px;
    z-index: 0; */
    display: none;
  }
  .scrollItemsWrapper {
  }
  .bottomDecorStyle {
    color: white;
    display: flex;
    /* width: 70%; */
    position: absolute;
    right: -100px;
    bottom: 140px;
    transform: rotate(180deg);
    z-index: -1;
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .contentWrapper {
    padding: 60px 40px;
  }
  .topDecorStyle {
    display: none;
  }
  .bottomDecorStyle {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .contentContainersWrapper {
    display: flex;
    justify-content: center;
  }
  .topDecorStyle {
    color: white;
    display: none;
    position: absolute;
    left: -100px;
    top: -10px;
    z-index: 0;
  }
  .bottomDecorStyle {
    color: white;
    display: none;
    position: absolute;
    right: -200px;
    bottom: 100px;
    transform: rotate(180deg);
    z-index: -1;
  }
}
