.MainPageWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url('../../Images/background-desktop.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow-x: scroll;
  /* overflow-y: hidden; */
}

.MainPageWrapper::before {
  content: ' ';
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
}

.MainPageWrapper::-webkit-scrollbar {
  overflow-y: hidden;
  width: 10px;
  height: 0px;
}

.MainPageWrapper html::-webkit-scrollbar-track {
  /* background-color: darkgray; */
  opacity: 50%;
  background-color: rgba(0, 0, 0, 0.826);
}

.MainPageWrapper::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #7900ef;
}

.sideBarPositionWrapper {
  min-width: 400px;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .sideBarPositionWrapper {
    min-width: 300px;
  }
  .MainPageWrapper {
    background-image: url('../../Images/background-tablet.jpg');
  }
}

@media only screen and (max-width: 860px) {
  .sideBarPositionWrapper {
    min-width: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .sideBarPositionWrapper {
    min-width: 0px;
  }
  .MainPageWrapper {
    height: calc(100vh - 60px);
    background-image: url('../../Images/background-mobile.jpg');
  }
}
