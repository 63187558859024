/* 
html {
  background: #200122;  
background: -webkit-linear-gradient(to right, #6f0000, #200122);  
background: linear-gradient(to right, #6f0000, #200122); 

} */

* {
  box-sizing: border-box;
}

html {
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
}
body {
  overflow: hidden;
}
h2 {
  margin: 0 0 20px 0;
  line-height: 1;
  padding: 10px 0px;
  color: white;
  font-size: 1.8em;
}
.App {
  /* text-align: center; */
  /* background-image: URL('./Images/backgroundNode.png') !important; */

  /* background-color: #116466; */
}
.WindowTesterOn {
  /* visibility: visible; */
}
.WindowTesterOff {
  display: none;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  /* background-color: darkgray; */
  opacity: 50%;
  background-color: rgba(0, 0, 0, 0.826);
}

html::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #4b087b;
}

/* html {
  scrollbar-width: thin;
} */

/* apply background:linear-gradient */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
